import { BrowserModule }                            from '@angular/platform-browser';
import { NgModule }                                 from '@angular/core';
import { AppComponent }                             from './app.component';
import { HttpClientModule}                          from '@angular/common/http';
import { NoopAnimationsModule }                     from '@angular/platform-browser/animations';
import { MatTabsModule }                            from '@angular/material/tabs';
//import { RouterModule, Routes }                     from '@angular/router';

/*const appRoutes: Routes = [
  { path: 'Start',                component:  AppComponent },
  { path: 'Ueber_uns',            component:  AppComponent },
  { path: 'Bestellen',            component:  AppComponent },
];*/

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatTabsModule,
    //RouterModule.forRoot(appRoutes, {})
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }
