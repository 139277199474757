/*
Webseite Sozialkueche
###############################

Version 1.02                                         
*/

import { AfterViewInit, Component, Directive, QueryList, ViewChildren } from '@angular/core';
import { HttpClient}                                                    from '@angular/common/http';
import { DomSanitizer, SafeHtml}                                        from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent {
  
  title                               = 'webseite-sozialkueche';
  navLinks: any[];

  aktuelleSeite : string;

  // Seiteninhalte
  Start      : SafeHtml;
  Ueber_uns  : SafeHtml;
  Impressum  : SafeHtml;
  HTMLInhalt : SafeHtml; // aktueller Inhalt

  @ViewChildren('Inhalt') inhalte: QueryList<any>;
  
  ngAfterViewChecked() {
    this.inhalte.forEach(inhalt => {
      if (inhalt.nativeElement.firstElementChild != null) {
        let maillinks = inhalt.nativeElement.getElementsByTagName('yoomail');
        console.log(inhalt.nativeElement);
        for (let maillink of maillinks){
          console.log('neu1:');
          console.log(maillink);
          maillink.innerHTML=this.YooMail(maillink.getAttribute("coded_prefix"),maillink.getAttribute("coded_domain"),maillink.getAttribute("coded_name"));
        }
      }
    }); 
   }

  // Definition der in der Hauptnaviagtion
  constructor(public http : HttpClient, private sanitizer: DomSanitizer) {

    this.navLinks = [    
      {
        label: 'START',
        link: 'Start',
        index: 1,
        status: "showStart",
        aktiv: false
      }, {
        label: 'IMPRESSUM',
        link: 'impressum',
        index: 2,
        status: "showImpressum",
        aktiv: false
      }
    ];
  }

  getSeite(link : any){
    
    this.navLinks.forEach((element) => {
      element.aktiv = false;
    });
    
    link.aktiv = true;
    switch(link.status){
        case "showUeberUns": 
          this.HTMLInhalt     = this.Ueber_uns;
          break;
        case "showImpressum": 
          this.HTMLInhalt     = this.Impressum;
          break;
        default:
          this.HTMLInhalt     = this.Start;
          break;  
      this.ngAfterViewChecked();
    }

    // url aktualisieren - man soll beim neu laden auf der gleichen Seite landen
    window.history.pushState("object or string", link.label, link.link);
  }

  ngOnInit(): void {

    this.http.get('./assets/HTML/Start.html', {
      responseType: 'text'
     }).subscribe((data:any) => {
       this.Start = this.sanitizer.bypassSecurityTrustHtml(data);
       this.jump("Start");
     });

     this.http.get('./assets/HTML/Ueber_uns.html', {
      responseType: 'text'
     }).subscribe((data:any) => {
       this.Ueber_uns = this.sanitizer.bypassSecurityTrustHtml(data);
       this.jump("Ueber_uns");
     });

    this.http.get('./assets/HTML/Impressum.html', {
     responseType: 'text'
    }).subscribe((data:any) => {
      this.Impressum = this.sanitizer.bypassSecurityTrustHtml(data);
      this.jump("Impressum");
    });
    
    // schauen, welche Seite wir beim Start aufrufen müssen. Start ist default
    this.aktuelleSeite = "start"

    // in der htaccess schreiben wir die URL so um, dass das PHP den Get-Parameter abfragen kann. 
    // Allerdings machen wir das im Hintergrund - der Client, der das losschickt bekommt davon nichts mit.

    // stattdessen lesen wir die URL aus, nehmen den letzten Teil, trennen die Sprungmarke ab und Voilá :-)
    // Das mit dem letzten Teil ist nötig, damit das auf dem Test- wie auf dem Livesystem funktioniert.
    // Edit: pathname ist schon ohne Sprungmarke, selbst wenn vorhanden
    var url = window.location.pathname;
    if(url != null && url != "") {
      var urlParts = url.toLowerCase().split('/');
      this.aktuelleSeite = urlParts[urlParts.length - 1];
    }

  }

  YooMail(coded_prefix:String,coded_domain:String,name:String) {
    let cipher = "aZbYcXdWeVfUgThSiRjQkPlOmNnMoLpKqJrIsHtGuFvEwDxCyBzA1234567890";
    let coded=coded_prefix+'@'+coded_domain;
    let shift=coded.length;
    let link = '<';
    link += 'a href="mailto:';
    for (let i=0; i<coded.length; i++){
      if (cipher.indexOf(coded.charAt(i))==-1) {
        let ltr=coded.charAt(i);
        link+=(ltr);
      }
      else {  
        let ltr = (cipher.indexOf(coded.charAt(i))-shift+cipher.length) % cipher.length;
        link+=(cipher.charAt(ltr));
      }
    }
    link += '">';
    link += name;
    link += '<';
    link += '/a';
    link +='>';
    return link;
  }

  /**
   * springt nach dem Laden der Inhalte zu der in der URL angegebenen Stelle
   * Nötig, weil wir die Inhalte versetzt laden  - wenn jemand aber mit Ueber_uns#Datenschutz reinkommt, sollte das schon funktionieren...
   */ 
  jump(erwarteteSeite: String) {

    if(location.hash != "") {
      var urlParts = (window.location.pathname == "" ? "Start" : window.location.pathname).split('/');
      console.log('Check ' + erwarteteSeite);
      if( urlParts[urlParts.length - 1].toLowerCase() == erwarteteSeite.toLowerCase()) {
        // TODO: funktioniert noch nicht :-/
        var hash = location.hash;
        location.hash = "notHere";
        location.hash = hash;
      }
    }
    // Index.PHP muss analog geändert werden, wenn neue Seiten hinzukommen
    switch (this.aktuelleSeite) {
      case "impressum":
        this.HTMLInhalt=this.Impressum;
        this.navLinks[2].aktiv = true;
        break;
      case "ueber_uns":
        this.HTMLInhalt=this.Ueber_uns;
        this.navLinks[1].aktiv = true;
        break;
      default:
        // Start und alles nicht erkannte
        this.HTMLInhalt=this.Start;
        this.navLinks[0].aktiv = true;
        break;
    }
  }
}
